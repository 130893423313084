import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useSetUserRole } from 'store/user';

import {
  AddNewOrganisationAction,
  Organisation,
  SetAllOrganisationsAction,
  SetSelectedOrganisationAction,
} from './types';

export const SET_ALL_ORGANISATIONS = 'SET_ALL_ORGANISATIONS';
export const SET_SELECTED_ORGANISATION = 'SET_SELECTED_ORGANISATION';
export const ADD_NEW_ORGANISATION = 'ADD_NEW_ORGANISATION';

export const baseActions = {
  setAll: (organisations: Organisation[]): SetAllOrganisationsAction => ({
    type: SET_ALL_ORGANISATIONS,
    organisations,
  }),

  addNew: (organisation: Organisation): AddNewOrganisationAction => ({
    type: ADD_NEW_ORGANISATION,
    organisation,
  }),

  setSelected: (organisation: Organisation): SetSelectedOrganisationAction => ({
    type: SET_SELECTED_ORGANISATION,
    organisation,
  }),
};

export const useSetAllOrganisations = (): typeof baseActions.setAll => {
  const dispatch = useDispatch();
  return useCallback(organisations => dispatch(baseActions.setAll(organisations)), [dispatch]);
};

export const useAddNewOrganisation = (): typeof baseActions.addNew => {
  const dispatch = useDispatch();
  return useCallback(organisation => dispatch(baseActions.addNew(organisation)), [dispatch]);
};

export const useSetSelectedOrganisation = (): typeof baseActions.setSelected => {
  const dispatch = useDispatch();
  const setUserRole = useSetUserRole();

  return useCallback(organisation => {
    setUserRole(organisation.role);
    return dispatch(baseActions.setSelected(organisation));
  }, [dispatch, setUserRole]);
};
