import React, { FC } from 'react';
import { FormApi } from 'final-form';
import classnames from 'classnames';

import { Form, Button, Input } from 'components/forms';
import { useIsAdmin } from 'store/user';
import { adminNeededTitle } from 'environment';

export const LinkForm: FC<LinkFormProps> = ({ onSubmit, initialData, className }) => {
  const isAdmin = useIsAdmin();

  return (
    <div className={ classnames('ppo-link-form', className) }>
      <Form
        onSubmit={ onSubmit }
        initialValues={ initialData }
        contentClassName="form-content"
        title={ !isAdmin ? adminNeededTitle : undefined }
      >
        <Input name="linkUrl" placeholder="Link URL" disabled={ !isAdmin } />
        <Input name="linkName" placeholder="Link Name" disabled={ !isAdmin } />
        <Input name="linkWidth" placeholder="Link Width" disabled={ !isAdmin } />
        <Input name="linkHeight" placeholder="Link Height" disabled={ !isAdmin } />

        <Button
          type="primary"
          htmlType="submit"
          className="new-link"
          icon="plus"
          size="large"
          htmlDisabled={ !isAdmin }
        >
          { initialData ? 'Update link' : 'Add new link' }
        </Button>
      </Form>
    </div>
  );
};
interface LinkFormProps {
  onSubmit: LinkSubmitFn;
  initialData?: LinkFormData;
  className?: string;
}

export interface LinkFormData {
  linkUrl: string;
  linkName: string;
  linkWidth: number;
  linkHeight: number;
}

export type LinkSubmitFn = (data: LinkFormData, form: FormApi<LinkFormData>) => Promise<boolean | undefined>
