import { AppActionTypes } from '../types';
import { OrganisationState } from './types';
import {
  SET_ALL_ORGANISATIONS,
  SET_SELECTED_ORGANISATION,
  ADD_NEW_ORGANISATION,
} from './actions';

const initialOrganisationState: OrganisationState = {
  selected: null,
  all: [],
};

// eslint-disable-next-line default-param-last
export const organisationReducer = (state = initialOrganisationState, action: AppActionTypes): OrganisationState => {
  switch (action.type) {
    case SET_ALL_ORGANISATIONS: {
      return {
        ...state,
        all: action.organisations,
      };
    }
    case SET_SELECTED_ORGANISATION: {
      return {
        ...state,
        selected: action.organisation,
      };
    }
    case ADD_NEW_ORGANISATION: {
      return {
        ...state,
        all: [...state.all, action.organisation],
      };
    }

    default: {
      return state;
    }
  }
};

export * from './actions';
export * from './selectors';
