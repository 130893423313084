export const fbAppId = String(process.env.FB_APP_ID);
export const googleClientId = String(process.env.GOOGLE_CLIENT_ID);

export const environment = (() => {
  const { host } = window.location;
  if (host.endsWith('dev.projekcijos.lt')) {
    return 'development';
  }
  if (host.endsWith('local.projekcijos.lt')) {
    return 'local';
  }
  return 'production';
})();

const API_PREFIX = (() => {
  if (environment === 'development') return 'dev.';
  if (environment === 'local') return 'local.';
  return '';
})();
export const apiBase = `https://api.${API_PREFIX}projekcijos.lt`;

export const isDevelopment = environment === 'development';
export const isLocal = environment === 'local';
export const isProduction = environment === 'production';

export const adminNeededTitle = 'Admin permissions required for this functionality';
export const publisherNeededTitle = 'Publisher permissions required for this functionality';

export const STORAGE_PREFIX = 'portal-';
export const cookieOptions = {
  authKey: `${STORAGE_PREFIX}authorization`,
  loggedInKey: `${STORAGE_PREFIX}logged-in`,
  lastUrlKey: `${STORAGE_PREFIX}last-url`,
  domain: (() => {
    if (environment === 'development') {
      return 'dev.projekcijos.lt';
    }
    if (environment === 'local') {
      return 'local.projekcijos.lt';
    }
    return 'projekcijos.lt';
  })(),
};
