import React, { FC, ReactNode } from 'react';
import classnames from 'classnames';

import { Spinner } from '../spinner/Spinner';

import './Section.scss';

export const Section: FC<SectionProps> = ({
  className: providedClassName,
  contentClassName,
  headingClassName,
  heading,
  disabled,
  title,
  loading = false,
  children,
}) => {
  const className = classnames(
    'wnf-section',
    disabled && 'disabled',
    providedClassName,
  );

  return (
    <section className={ className } title={ title }>
      { heading && <h2 className={ classnames('wnf-section-heading', headingClassName) }>{ heading }</h2> }

      <div className={ classnames('wnf-section-content', contentClassName) }>
        { children }
      </div>

      <Spinner visible={ loading } iconSize={ 40 } />
    </section>
  );
};

interface SectionProps {
  heading?: ReactNode;
  className?: string;
  contentClassName?: string;
  headingClassName?: string;
  disabled?: boolean;
  loading?: boolean;
  title?: string;
}
