import { useSelector } from 'react-redux';

import { AppState } from '../types';
import { OrganisationState } from './types';

export const useAllOrganisations = () => (
  useSelector<AppState, OrganisationState['all']>(({ organisation }) => (
    organisation.all
  ))
);

export const useSelectedOrganisation = () => (
  useSelector<AppState, OrganisationState['selected']>(({ organisation }) => (
    organisation.selected
  ))
);
