import { API } from 'api';

import { ScraperFormData } from 'pages/authorised/scrapers/ScraperConfig';

import { Scraper } from './types';

const SCRAPERS_API_PREFIX = 'scrapers';

export const scrapersAPI = {
  getAll: async (orgId: string) => {
    const response = await API.get<Scraper[]>(`${SCRAPERS_API_PREFIX}/${orgId}/all`);
    return response.data;
  },
  addNew: async (orgId: string, data: any) => {
    const response = await API.post<string>(`${SCRAPERS_API_PREFIX}/${orgId}/add-new`, data);
    return response.data;
  },
  edit: async (scraperId: string, data: ScraperFormData) => {
    const response = await API.post<string>(`${SCRAPERS_API_PREFIX}/${scraperId}/edit`, data);
    return response.data;
  },
  run: async (id: string) => {
    const response = await API.get<any[]>(`${SCRAPERS_API_PREFIX}/run/${id}`);
    return response.data;
  },
};
