import React, { FC, useEffect } from 'react';
import { Route } from 'react-router-dom';

import {
  useAuthenticate,
  useIsAuthenticating,
  useIsLoggedIn,
} from 'store/user';

import { Authorised } from 'routes/Authorised';
import { Unauthorised } from 'routes/Unauthorised';
import { Spinner } from 'components/common';
import { Tag } from 'components/tag/Tag';

export const App: FC = () => {
  const isLoggedIn = useIsLoggedIn();
  const authenticate = useAuthenticate();
  const isAuthenticating = useIsAuthenticating();

  useEffect(() => { authenticate(); }, [authenticate]);

  if (isAuthenticating) {
    return <Spinner type="dark" />;
  }

  return (
    <div className="demos-app">
      <Route path="/:orgId?" component={ isLoggedIn ? Authorised : Unauthorised } />
      <Tag />
    </div>
  );
};
