import React, { FC } from 'react';
import classnames from 'classnames';
import AntButton, { ButtonProps as AntButtonProps } from 'antd/lib/button';

import { IconType, Icon } from '../../common';

import './Button.scss';

export const Button: FC<ButtonProps> = ({
  className: passedClassName,
  type = 'default',
  icon,
  width,
  disabled,
  htmlDisabled,
  ...rest
}) => (
  <AntButton
    className={ classnames('pro-button', disabled && 'disabled', passedClassName) }
    type={ type }
    icon={ icon && <Icon type={ icon } /> }
    style={ width ? { width } : undefined }
    disabled={ htmlDisabled }
    { ...rest }
  />
);

export interface ButtonProps extends Omit<AntButtonProps, 'icon'> {
  className?: string;
  icon?: IconType;
  width?: number;
  height?: number;
  htmlDisabled?: boolean;
}
