import { AppActionTypes } from '../types';
import { UserState, User } from './types';
import {
  BEFORE_AUTHENTICATE,
  AFTER_AUTHENTICATE,
  AFTER_LOGOUT,
  AFTER_AUTHENTICATE_ERROR,
  SET_USER_ROLE,
} from './actions';

const initialUser: User = {
  _id: '',
  userID: 0,
  name: '',
  email: '',
};

const initialUserState: UserState = {
  isAuthenticating: true,
  isLoggedIn: false,
  user: initialUser,
  role: null,
};

// eslint-disable-next-line default-param-last
export const userReducer = (state = initialUserState, action: AppActionTypes): UserState => {
  switch (action.type) {
    case BEFORE_AUTHENTICATE: {
      return {
        ...state,
        isAuthenticating: true,
      };
    }
    case AFTER_AUTHENTICATE: {
      return {
        ...state,
        isAuthenticating: false,
        isLoggedIn: true,
        user: action.user,
      };
    }
    case AFTER_AUTHENTICATE_ERROR: {
      return {
        ...state,
        isAuthenticating: false,
        isLoggedIn: false,
      };
    }

    case SET_USER_ROLE: {
      return {
        ...state,
        role: action.role,
      };
    }

    case AFTER_LOGOUT: {
      return {
        ...initialUserState,
        isAuthenticating: false,
      };
    }

    default: {
      return state;
    }
  }
};

export * from './actions';
export * from './selectors';
