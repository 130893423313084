import React from 'react';
import notification, { ArgsProps } from 'antd/lib/notification';
import 'antd/lib/notification/style';

const showMessage = ({ message, ...rest }: ArgsProps, type: 'error' | 'success') => (
  notification.open({
    type,
    message: typeof message === 'string' ? (
      // eslint-disable-next-line
      <span dangerouslySetInnerHTML={ { __html: String(message) } } />
    ) : message,
    ...rest,
  })
);

export const notifications = {
  error: (options: ArgsProps) => showMessage(options, 'error'),
  success: (options: ArgsProps) => showMessage(options, 'success'),
};
