import React, { FC, Fragment, useCallback, useState } from 'react';
import classnames from 'classnames';

import { GetUsersRespose, organisationAPI } from 'api/organisation';
import { Avatar, ConfirmTooltip, Icon, Spinner } from 'components/common';
import { Form, Input, Select, SelectOption } from 'components/forms';
import { UserRole } from 'store/user/types';
import { useApiCallback } from 'hooks/api';
import { useAuthenticate, useIsAdmin, useUser } from 'store/user';
import { userAPI, UserUpdatePasswordData } from 'api/user';
import { notifications } from 'utils/notifications';
import { adminNeededTitle } from 'environment';

export const roleOptions: SelectOption<UserRole>[] = [
  { value: UserRole.admin, label: 'Admin' },
  { value: UserRole.publisher, label: 'Publisher' },
  { value: UserRole.viewer, label: 'Viewer' },
];

export const UserCard: FC<UserCardProps> = ({ _id, email, name, role, orgId, reloadData }) => {
  const { callback: updateUserRole, isLoading } = useApiCallback(organisationAPI.updateUserRole);
  const { callback: updatePassword } = useApiCallback(userAPI.updatePassword);
  const authenticate = useAuthenticate();
  const currentUserId = useUser()._id;
  const isAdmin = useIsAdmin();
  const [errorKey, setErrorKey] = useState(0);
  const [passwordKey, setPasswordKey] = useState(0);
  const handleRoleChange = useCallback(async (newRole: UserRole) => {
    const roleName = roleOptions.find(({ value }) => value === newRole)?.label;

    try {
      await updateUserRole(orgId, _id, newRole);

      notifications.success({
        message: <Fragment> Updated <b>{ email }</b> to <b>{ roleName }</b> </Fragment>,
      });

      if (currentUserId === _id) {
        authenticate(true);
      } else {
        reloadData(orgId);
      }
    } catch {
      setErrorKey(oldKey => oldKey + 1);
    }
  }, [orgId, _id, email, currentUserId, updateUserRole, reloadData, authenticate]);
  const handlePasswordChange = useCallback(async (data: UserUpdatePasswordData) => {
    try {
      await updatePassword(data);
      notifications.success({ message: 'Password successfully updated' });
      setPasswordKey(oldKey => oldKey + 1);
    } catch {}
  }, [updatePassword]);

  return (
    <tr className="wnf-user-card" title={ `${email} | ${_id}` }>
      <td className="avatar-col">
        <Avatar size="large" icon={ <Icon type="user" /> } />
      </td>

      { name && <td className="name">{ name }</td> }
      <td className="email">{ email }</td>

      <td className="password-col">
        <div
          className={ classnames('role', !isAdmin && 'disabled') }
          title={ !isAdmin ? adminNeededTitle : undefined }
        >
          <ConfirmTooltip
            key={ errorKey }
            title="If you change your own role you will lose admin access to the organisation"
            okButtonProps={ { hidden: true } }
            cancelButtonProps={ { hidden: true } }
            disabled={ currentUserId !== _id }
          >
            <Select
              options={ roleOptions }
              onChange={ handleRoleChange }
              value={ role }
              disabled={ !isAdmin }
            />
          </ConfirmTooltip>
        </div>
      </td>

      <td className="password-col">
        { currentUserId === _id && (
          <Form
            onSubmit={ handlePasswordChange }
            className="password-update"
            contentClassName="fields"
            buttonLabel="Update"
            buttonSize="middle"
            key={ passwordKey }
          >
            <Input
              name="oldPassword"
              type="password"
              placeholder="Old Password"
              height={ 32 }
              required
            />

            <Input
              name="newPassword"
              type="password"
              placeholder="New Password"
              minLength={ 8 }
              height={ 32 }
              required
            />
          </Form>
        ) }

        <Spinner iconSize={ 30 } visible={ isLoading } />
      </td>
    </tr>
  );
};

interface UserCardProps extends GetUsersRespose {
  orgId: string;
  reloadData: (orgId: string) => any;
}
