import { ScraperTypeEnum } from 'types';

const browser = `
async function() {
  // This function is executed in a browser context on the page
  // you specify in the URL field above. This means you have full
  // access to the regular Browsed APIs like document.querySelector
  // Construct the data you want to be saved and return it from this function
  const data = {};

  return data;
}
`.trim();
const api = `
async function(axios) {
  // This function is executed without a context. A single prop (axios) is
  // passed to the funciton - you can use this prop to make HTTP requests
  // to other resources. You can read more about Axios here: https://github.com/axios/axios
  // Construct the data you want to be saved and return it from this function
  const data = {};

  return data;
}
`.trim();
const graphApi = `
async function (data) {
  // This function is executed without a context. A single prop (data) is passed to the funciton.
  // data is the selected Facebook page posts and events data as returned by Facebook Graph API.
  // You can read more abut the data here: https://developers.facebook.com/docs/graph-api/reference/v9.0/page/feed
  // Construct the data you want to be saved and return it from this function

  return data;
}
`.trim();
const NodeJS = `
async function (page, makeScreenshot) {
  // This function is executed in a NodeJS context. Two props (page & makeScreenshot) are
  // passed to the funciton - you can use the page (Puppeteer page) prop to scrape any desired url and use the 
  // makeScreenshot prop to make a screenshot of the page you're currently. The screenshot will
  // be saved in the database and associated with this specific scraper. You can read more about
  // Puppeteer page object here: https://pptr.dev/#?product=Puppeteer&version=v5.5.0&show=api-class-page
  // Construct the data you want to be saved and return it from this function
  const data = {};

  return data;
}
`.trim();

export const defaultHandlers: Record<ScraperTypeEnum, string> = {
  [ScraperTypeEnum.Browser]: browser,
  [ScraperTypeEnum.API]: api,
  [ScraperTypeEnum.Facebook]: graphApi,
  [ScraperTypeEnum.Instagram]: graphApi,
  [ScraperTypeEnum.NodeJS]: NodeJS,
};

export const defaultHandlersArray = Object.values(defaultHandlers);
