import React, { FC, useCallback, useMemo } from 'react';
import { Field, FieldProps, FieldRenderProps } from 'react-final-form';
import { FormApi } from 'final-form';
import classnames from 'classnames';

import { getDefaultValidators, FieldValidator } from 'utils/validation';

import { Message } from '../../common';
import { Select, SelectProps, SelectFieldValue } from './Select';

import './SelectField.scss';

const defaultValidators: SelectValidators = [];

const SelectFieldRenderer: FC<SelectFieldRendererProps> = ({
  className: passedClassName,
  meta: { error, touched, submitError },
  checkIsTouched = true,
  input,
  horizontal,
  onChange,
  ...props
}) => {
  const errorMessage = (touched || !checkIsTouched) && (error || submitError);
  const className = classnames(
    'pro-form-field pro-select',
    errorMessage ? 'has-error' : 'no-error',
    horizontal && 'horizontal',
    passedClassName,
  );

  return (
    <div className={ className }>
      <Select
        { ...input }
        { ...props }
        onChange={ (value, option) => {
          if (onChange) {
            onChange(value, option);
          }

          input.onChange({ target: { value } });
        } }
      />

      <Message
        message={ errorMessage }
        isOpen={ !!errorMessage }
        type="error"
        simple
      />
    </div>
  );
};

export const SelectField: FC<SelectFieldProps> = ({
  name,
  validators: customValidators = defaultValidators,
  component = SelectFieldRenderer,
  required,
  label,
  ...rest
}) => {
  const validators = useMemo(() => (
    getDefaultValidators<SelectFieldValue>({
      inputType: 'select',
      required,
      label,
    }).concat(customValidators || [])
  ), [label, required, customValidators]);

  const validator = useCallback((value: SelectFieldValue, allValues: any, formApi: FormApi) => (
    validators.reduce<string | void>((error, validate) => (
      error || validate(value, allValues, formApi)
    ), undefined)
  ), [validators]);

  return (
    <Field
      name={ name }
      type="select"
      validate={ validator as any }
      component={ component }
      required={ required }
      label={ label }
      { ...rest }
    />
  );
};

interface SelectFieldRendererCustomProps extends SelectProps {
  checkIsTouched?: boolean;
  horizontal?: boolean;
  required?: boolean;
}

type SelectFieldRendererProps = (
  FieldRenderProps<SelectFieldValue>
  & SelectFieldRendererCustomProps
)

export type SelectValidators = FieldValidator<SelectFieldValue>[];

export type SelectFieldProps = (
  FieldProps<any, SelectFieldRendererProps>
  & SelectFieldRendererCustomProps
)
