import { API } from 'api';
import { LinkFormData } from 'pages/authorised/links/LinkForm';

import { Link } from './types';

const LINKS_API_PREFIX = 'links';

export const linksAPI = {
  getAll: async (_id: string) => {
    const response = await API.get<Link[]>(`${LINKS_API_PREFIX}/${_id}/all`);
    return response.data;
  },
  addNew: async (_id: string, data: LinkFormData) => {
    const response = await API.post<string>(`${LINKS_API_PREFIX}/${_id}/add-new`, data);
    return response.data;
  },
  edit: async (scraperID: string, data: LinkFormData) => {
    const response = await API.post<string>(`${LINKS_API_PREFIX}/${scraperID}/edit`, data);
    return response.data;
  },
};
