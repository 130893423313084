import React, { FC, Fragment, useEffect, useState } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import Cookies from 'cookies-js';

import { cookieOptions } from 'environment';
import { LinksPage, ScrapersPage, SettingsPage } from 'pages/authorised';
import { Header } from 'components/layouts';
import { useAllOrganisations, useSelectedOrganisation, useSetSelectedOrganisation } from 'store/organisation';
import { useAuthenticate } from 'store/user';

export const Authorised: FC = () => {
  const setSelectedOrganisation = useSetSelectedOrganisation();
  const { params: { orgId }, path } = useRouteMatch<{ orgId: string }>();
  const [isVerified, setIsVerified] = useState(false);
  const organisations = useAllOrganisations();
  const selectedOrgId = useSelectedOrganisation()?.orgId;
  const authenticate = useAuthenticate();
  const history = useHistory();

  useEffect(() => {
    const redirectToFirst = () => {
      if (organisations[0] && !Cookies.get(cookieOptions.lastUrlKey)) {
        history.replace(`/${organisations[0].orgId}/scrapers`);
      }
    };

    if (!organisations.some(org => org.orgId === +orgId)) {
      redirectToFirst();
      return setIsVerified(true);
    }

    if (orgId) {
      const selectedOrganisation = organisations.find(org => org.orgId === +orgId);

      if (selectedOrganisation) {
        setSelectedOrganisation(selectedOrganisation);
      } else {
        redirectToFirst();
      }
    }

    return setIsVerified(true);
  }, [orgId, history, organisations, setSelectedOrganisation]);

  useEffect(() => {
    if (selectedOrgId && selectedOrgId !== +orgId) {
      authenticate(true);
    }
  }, [authenticate, selectedOrgId, orgId]);

  if (!isVerified) {
    return null;
  }

  return (
    <Fragment>
      <Header />

      <Switch>
        <Route path={ `${path}/links` } exact component={ LinksPage } />
        <Route path={ `${path}/scrapers` } exact component={ ScrapersPage } />
        <Route path={ `${path}/settings` } exact component={ SettingsPage } />

        <Route render={ () => <h1 style={ { textAlign: 'center' } }>404 - Not found</h1> } />
      </Switch>
    </Fragment>
  );
};
