import React, { FC, useEffect, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { notifications } from 'utils/notifications';
import { useSelectedOrganisation } from 'store/organisation';
import { Spinner } from 'components/common';
import { resolveError } from 'api/utils';
import { Link } from 'api/links/types';
import { linksAPI } from 'api/links';
import { useApiCallback } from 'hooks/api';

import { LinkForm } from './LinkForm';
import { LinkItem } from './LinkItem';

import './LinksPage.scss';

export const LinksPage: FC<RouteComponentProps> = () => {
  const organisation = useSelectedOrganisation();
  const orgId = organisation?._id;
  const { data: links, isLoading, callback } = useApiCallback(linksAPI.getAll, []);
  const loadData = useCallback(() => orgId && callback(orgId), [callback, orgId]);
  const createLink = useCallback(async (data, form) => {
    try {
      if (orgId) {
        const message = await linksAPI.addNew(orgId, {
          ...data,
          linkWidth: +data.linkWidth,
          linkHeight: +data.linkHeight,
        });
        notifications.success({ message });

        loadData();

        setTimeout(() => form.reset(), 250);
        return true;
      }
    } catch (err) {
      const updateError = resolveError(err);
      notifications.error(updateError);
    }
  }, [orgId, loadData]);

  useEffect(() => { loadData(); }, [loadData]);

  return (
    <div className="ppo-links-page">
      <div className="links-list">
        { links.map(link => <LinkItem key={ link._id } { ...link } loadData={ loadData } />) }
      </div>

      <LinkForm className="new-link-form" onSubmit={ createLink } />

      <Spinner visible={ isLoading } />
    </div>
  );
};

export type LinkUpdateFn = (_id: string, data: Partial<Link>) => Promise<boolean | undefined>
