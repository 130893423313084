import { AxiosError, AxiosRequestConfig } from 'axios';

export interface ErrorModel {
  message: string;
  status: number;
  fields?: { [key: string]: string };
}

export const resolveError = (error?: AxiosError<any>): ErrorModel => {
  if (error) {
    const { response, message } = error;
    let errorMessage = '';

    if (response) {
      const { status, data } = response;

      if (status === 404) {
        errorMessage = data || data?.message || 'Service not found';
      }

      return {
        message: errorMessage || data || data?.message || message || 'Unknown error (FE)',
        fields: data?.fields,
        status,
      };
    }

    return {
      message: message || 'Unknown error (FE)',
      status: 500,
    };
  }

  return {
    message: 'Unknown error (FE)',
    status: 0,
  };
};

export const getHeaders = (): AxiosRequestConfig => ({
  withCredentials: true,
});
