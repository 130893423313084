import { ReactFacebookLoginInfo } from 'react-facebook-login';

import { API } from 'api';
import { AccessToken } from 'store/organisation/types';

const ACCESS_TOKENS_API_PREFIX = 'access-tokens';

export const accessTokenAPI = {
  getAll: async (orgId: string) => {
    const response = await API.get<AccessToken[]>(`${ACCESS_TOKENS_API_PREFIX}/${orgId}`);
    return response.data;
  },
  addNewFacebookToken: async (orgId: string, userData: ReactFacebookLoginInfo) => {
    const response = await API.post<AccessToken[]>(`${ACCESS_TOKENS_API_PREFIX}/${orgId}/facebook`, userData);
    return response.data;
  },
  addNewInstagramToken: async (orgId: string, userData: ReactFacebookLoginInfo) => {
    const response = await API.post<AccessToken[]>(`${ACCESS_TOKENS_API_PREFIX}/${orgId}/instagram`, userData);
    return response.data;
  },
  revokeTokens: async (orgId: string) => {
    const response = await API.delete<{ success: boolean }>(`${ACCESS_TOKENS_API_PREFIX}/${orgId}`);
    return response.data;
  },
  updateInfo: async (orgId: string) => {
    const response = await API.post<AccessToken[]>(`${ACCESS_TOKENS_API_PREFIX}/${orgId}/update-info`, {});
    return response.data;
  },
};
