import React, { FC, useMemo } from 'react';
import classnames from 'classnames';

import { Scraper } from 'api/scrapers/types';
import { Icon } from 'components/common';
import { ScraperTypeEnum } from 'types';

import { scraperTypeLabels } from './ScraperConfig';

export const ScraperType: FC<ScraperTypeProps> = ({ type }) => {
  const { label, icon } = useMemo(() => {
    const isApiScraper = [
      ScraperTypeEnum.API,
      ScraperTypeEnum.Facebook,
      ScraperTypeEnum.Instagram,
    ].includes(type);

    if (type === ScraperTypeEnum.Browser) {
      return {
        label: scraperTypeLabels[ScraperTypeEnum.Browser],
        icon: <Icon className="icon" type="computer" />,
      };
    }

    if (isApiScraper) {
      return {
        label: scraperTypeLabels[type],
        icon: <Icon className="icon" type="api" />,
      };
    }

    if (type === ScraperTypeEnum.NodeJS) {
      return {
        label: scraperTypeLabels[ScraperTypeEnum.NodeJS],
        icon: <Icon className="icon" type="server" />,
      };
    }

    return {
      label: 'Unknown',
      icon: null,
    };
  }, [type]);

  return (
    <span className={ classnames('type-block', `type-${type}`) }>
      { icon }
      { label }
    </span>
  );
};

interface ScraperTypeProps {
  type: Scraper['type'];
}
