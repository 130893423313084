import { Organisation } from 'store/organisation/types';
import { User } from 'store/user/types';

import { API } from './index';

const USER_API_PREFIX = 'users';

export const userAPI = {
  authenticate: async () => {
    const response = await API.get<UserResponse>(`${USER_API_PREFIX}/authenticate`);
    return response.data;
  },

  register: async (data: UserRegisterData) => {
    const response = await API.post<UserResponse>(`${USER_API_PREFIX}/register`, data);
    return response.data;
  },

  login: async (data: UserLoginData) => {
    const response = await API.post<UserResponse>(`${USER_API_PREFIX}/login`, data);
    return response.data;
  },

  logout: async () => {
    const response = await API.post<void>(`${USER_API_PREFIX}/logout`, {});
    return response.data;
  },

  updatePassword: async (data: UserUpdatePasswordData) => {
    const response = await API.post<UserResponse>(`${USER_API_PREFIX}/update-password`, data);
    return response.data;
  },
};

export interface UserResponse extends User {
  organisations: Organisation[];
}

export interface UserRegisterData {
  name: string;
  email: string;
  password: string;
}

export interface UserLoginData {
  email: string;
  password: string;
}

export interface UserUpdatePasswordData {
  oldPassword: string;
  newPassword: string;
}
