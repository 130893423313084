/* eslint-disable camelcase */
import { Action } from 'redux';

import {
  BEFORE_AUTHENTICATE,
  AFTER_AUTHENTICATE,
  AFTER_LOGOUT,
  AFTER_AUTHENTICATE_ERROR,
  SET_USER_ROLE,
} from './actions';

export enum UserRole {
  admin = 'admin',
  publisher = 'publisher',
  viewer = 'viewer',
}

export interface User {
  _id: string;
  userID: number;
  email: string;
  name: string;
}

export interface UserState {
  user: User;
  role: UserRole | null;
  isLoggedIn: boolean;
  isAuthenticating: boolean;
}

export interface BeforeAuthenticateAction extends Action {
  type: typeof BEFORE_AUTHENTICATE;
}

export interface AfterAuthenticateAction extends Action {
  type: typeof AFTER_AUTHENTICATE;
  user: User;
}

export interface AfterAuthenticateErrorAction extends Action {
  type: typeof AFTER_AUTHENTICATE_ERROR;
}

export interface AfterLogourAction extends Action {
  type: typeof AFTER_LOGOUT;
}

export interface SetUserRoleAction extends Action {
  type: typeof SET_USER_ROLE;
  role: UserRole;
}

export type UserActionTypes = BeforeAuthenticateAction
| AfterAuthenticateAction
| AfterAuthenticateErrorAction
| AfterLogourAction
| SetUserRoleAction
