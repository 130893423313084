import React, { FC, useState, useEffect } from 'react';

import './Tag.scss';

export const Tag: FC = () => {
  const [isOpen, setIsOpen] = useState(window.localStorage.getItem('tag-visible') === 'true');

  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      if (event.altKey && event.code === 'KeyV') {
        event.preventDefault();
        setIsOpen(old => {
          window.localStorage.setItem('tag-visible', String(!old));
          return !old;
        });
      }
    };
    window.addEventListener('keydown', handler);
    return () => window.removeEventListener('keypress', handler);
  }, []);

  if (!isOpen) {
    return null;
  }

  return (
    <div className="version-tag">
      Version: <b>{process.env.TAG}</b>
    </div>
  );
};
