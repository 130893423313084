import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Cookies from 'cookies-js';

import { userAPI, UserLoginData, UserRegisterData, UserResponse } from 'api/user';
import { resolveError } from 'api/utils';
import { cookieOptions } from 'environment';
import { notifications } from 'utils/notifications';

import { useSetAllOrganisations } from '../organisation/actions';
import {
  User,
  BeforeAuthenticateAction,
  AfterAuthenticateAction,
  AfterAuthenticateErrorAction,
  AfterLogourAction,
  SetUserRoleAction,
  UserRole,
} from './types';

export const BEFORE_AUTHENTICATE = 'BEFORE_AUTHENTICATE';
export const AFTER_AUTHENTICATE = 'AFTER_AUTHENTICATE';
export const AFTER_AUTHENTICATE_ERROR = 'AFTER_AUTHENTICATE_ERROR';

export const AFTER_LOGOUT = 'AFTER_LOGOUT';

export const SET_USER_ROLE = 'SET_USER_ROLE';

const baseActions = {
  beforeAuthenticate: (): BeforeAuthenticateAction => ({
    type: BEFORE_AUTHENTICATE,
  }),
  afterAuthenticate: (user: User): AfterAuthenticateAction => ({
    type: AFTER_AUTHENTICATE,
    user,
  }),
  afterAuthenticateError: (): AfterAuthenticateErrorAction => ({
    type: AFTER_AUTHENTICATE_ERROR,
  }),
  afterLogout: (): AfterLogourAction => ({
    type: AFTER_LOGOUT,
  }),
  setUserRole: (role: UserRole): SetUserRoleAction => ({
    type: SET_USER_ROLE,
    role,
  }),
};

const useUpdateUser = () => {
  const dispatch = useDispatch();
  const setAllOrganisations = useSetAllOrganisations();

  return useCallback(({ organisations, ...user }: UserResponse) => {
    dispatch(baseActions.afterAuthenticate(user));
    setAllOrganisations(organisations);
  }, [dispatch, setAllOrganisations]);
};

export const useRegister = () => {
  const dispatch = useDispatch();
  const updateUser = useUpdateUser();

  return useCallback(async (data: UserRegisterData) => {
    try {
      const user = await userAPI.register(data);
      updateUser(user);
      return user;
    } catch (error) {
      dispatch(baseActions.afterAuthenticateError());
      notifications.error(resolveError(error));
    }
  }, [dispatch, updateUser]);
};

export const useLogin = () => {
  const dispatch = useDispatch();
  const updateUser = useUpdateUser();
  const history = useHistory();

  return useCallback(async (data: UserLoginData) => {
    try {
      const user = await userAPI.login(data);
      const lastUrl = Cookies.get(cookieOptions.lastUrlKey);
      updateUser(user);

      history.push(lastUrl || '/');

      Cookies.expire(cookieOptions.lastUrlKey);
    } catch (error) {
      dispatch(baseActions.afterAuthenticateError());
      notifications.error(resolveError(error));
    }
  }, [dispatch, updateUser, history]);
};

export const useLogout = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  return useCallback(async () => {
    const { pathname } = window.location;

    if (Cookies.get(cookieOptions.loggedInKey)) {
      try {
        await userAPI.logout();
      } catch (error) {
        return notifications.error(resolveError(error));
      }
    }

    dispatch(baseActions.afterLogout());

    if (!['/login', '/register'].includes(pathname)) {
      Cookies.set(cookieOptions.lastUrlKey, pathname);
      history.push('/login');
    }
  }, [dispatch, history]);
};

export const useAuthenticate = () => {
  const dispatch = useDispatch();
  const updateUser = useUpdateUser();
  const logout = useLogout();

  return useCallback(async (silent = false) => {
    if (Cookies.get(cookieOptions.loggedInKey)) {
      if (!silent) {
        dispatch(baseActions.beforeAuthenticate());
      }

      try {
        const user = await userAPI.authenticate();
        return updateUser(user);
      } catch (error) {
        dispatch(baseActions.afterAuthenticateError());
        notifications.error(resolveError(error));
      }
    }

    logout();
  }, [dispatch, updateUser, logout]);
};

export const useSetUserRole = (): typeof baseActions.setUserRole => {
  const dispatch = useDispatch();
  return useCallback(role => dispatch(baseActions.setUserRole(role)), [dispatch]);
};
