import React, { FC, memo } from 'react';
import { Field, useField, FieldProps, FieldRenderProps } from 'react-final-form';
import classnames from 'classnames';
import AntRadio from 'antd/lib/radio';
import 'antd/lib/radio/style';

import './Radio.scss';

const RadioRenderer: FC<RadioRendererProps> = ({ input, label, className, title, ...rest }) => (
  <span
    className={ classnames('pro-radio-field', className) }
    title={ title }
  >
    <AntRadio { ...input } { ...rest }>{ label }</AntRadio>
  </span>
);

export const Radio: FC<RadioProps> = memo(({ name, options, onChange, ...rest }) => {
  const { input: { value } } = useField(name);

  return (
    <AntRadio.Group
      className="field"
      value={ value !== undefined ? value : rest.defaultValue }
      onChange={ onChange ? e => onChange(e.target.value) : undefined }
    >
      { options.map(option => (
        <Field
          name={ name }
          key={ option.value }
          render={ RadioRenderer }
          label={ option.label || option.value }
          type="radio"
          { ...option }
          { ...rest }
        />
      )) }
    </AntRadio.Group>
  );
});

interface RadioRendererProps extends FieldRenderProps<any, HTMLElement>, RadioOption {}

export interface RadioOption {
  value: any;
  label?: string;
  disabled?: boolean;
  title?: string;
  className?: string;
}
interface RadioProps extends FieldProps<any, RadioRendererProps> {
  name: string;
  options: RadioOption[];
  onChange?: (value: any) => any;
}
