import React, { FC, useCallback, useRef } from 'react';
import { NavLink, Link, useHistory } from 'react-router-dom';
import Select from 'antd/lib/select';
import 'antd/lib/select/style';

import { useIsAuthenticating, useLogout } from 'store/user';
import { useAddNewOrganisation, useAllOrganisations, useSelectedOrganisation } from 'store/organisation';
import { Organisation } from 'store/organisation/types';
import { organisationAPI } from 'api/organisation';

import { Icon, Modal } from '../common';
import { Form, Button, Input } from '../forms';

import './Header.scss';

const { Option } = Select;

export const Header: FC = () => {
  const logout = useLogout();
  const allOrganisations = useAllOrganisations();
  const organisation = useSelectedOrganisation();
  const isAuthenticating = useIsAuthenticating();
  const addNewOrganisation = useAddNewOrganisation();
  const history = useHistory();
  const modalRef = useRef<Modal>(null);
  const handleSuccess = useCallback((data: Organisation) => {
    if (modalRef.current) {
      modalRef.current.close();
    }

    addNewOrganisation(data);
    history.push(`/${String(data.orgId)}/settings`);
  }, [history, addNewOrganisation]);

  return (
    <div className="header">
      { organisation ? (
        <nav>
          <ul>
            <li>
              <NavLink
                to={ `/${organisation.orgId}/links` }
                activeClassName="active"
                exact
              >
                <Icon type="computer" marginRight={ 5 } /> Links
              </NavLink>
            </li>
            <li>
              <NavLink
                to={ `/${organisation.orgId}/scrapers` }
                activeClassName="active"
                exact
              >
                <Icon type="api" marginRight={ 5 } /> Scrapers
              </NavLink>
            </li>
            <li>
              <NavLink
                to={ `/${organisation.orgId}/settings` }
                activeClassName="active"
                exact
              >
                <Icon type="settings" marginRight={ 5 } /> Settings
              </NavLink>
            </li>
          </ul>
        </nav>
      ) : null }

      <Select
        value={ organisation?.name || 'Please select organisation' }
        loading={ isAuthenticating }
        className="org-select"
      >
        { allOrganisations.map(({ _id, orgId, name }) => {
          const currentUrl = window.location.pathname;
          const newUrl = currentUrl.includes(`/${organisation?.orgId}`)
            ? currentUrl.replace(`/${organisation?.orgId}`, `/${orgId}`)
            : `/${orgId}`;

          return (
            <Option key={ _id } className="org-option" value={ orgId }>
              <Link to={ newUrl }>
                { name }
              </Link>
            </Option>
          );
        }) }

        <Option className="org-option new-org" value="new">
          <Button icon="plus" onClick={ modalRef.current?.open }>New</Button>
        </Option>
      </Select>

      <Button
        className="logout"
        onClick={ logout }
        type="primary"
        danger
      >
        Logout
      </Button>

      <Modal heading="Enter the organisation name" ref={ modalRef }>
        <Form
          onSubmit={ organisationAPI.addNew }
          onSuccess={ handleSuccess }
          buttonLabel="Create"
        >
          <Input label="Name" name="name" />
        </Form>
      </Modal>
    </div>
  );
};
