import React, { FC, useCallback, useMemo, useState } from 'react';
import classnames from 'classnames';

import { notifications } from 'utils/notifications';
import { useElementResizeObserver } from 'hooks';
import { resolveError } from 'api/utils';
import { Link } from 'api/links/types';
import { linksAPI } from 'api/links';

import { Icon } from 'components/common';

import { LinkForm, LinkFormData } from './LinkForm';

const mapLinkData = ({ url, name, width, height }: Link): LinkFormData => ({
  linkUrl: url,
  linkName: name,
  linkWidth: width,
  linkHeight: height,
});

export const LinkItem: FC<LinkItemProps> = ({ loadData, ...link }) => {
  const { _id, width, height, url, name } = link;
  const [{ isOpen, isFormVisibleOpen }, setState] = useState<LinkItemState>({
    isOpen: false,
    isFormVisibleOpen: false,
  });
  const [containerRef, size] = useElementResizeObserver(
    useCallback(props => {
      const scale = props.contentRect.width / width;
      let iframeWidth = width * scale;
      let iframeHeight = height * scale;

      if (iframeHeight > 900) {
        const reduction = iframeHeight / 900;
        iframeWidth /= reduction;
        iframeHeight /= reduction;
      }

      return {
        width: iframeWidth,
        height: iframeHeight,
      };
    }, [width, height]),
  );

  const updateLink = useCallback(async data => {
    try {
      if (_id) {
        const message = await linksAPI.edit(_id, data);
        notifications.success({ message });

        loadData();

        return true;
      }
    } catch (err) {
      notifications.error(resolveError(err));
    }
  }, [_id, loadData]);

  const initialData = useMemo(() => mapLinkData(link), [link]);
  const className = classnames(
    'ppo-link-item',
    isOpen && 'open',
  );

  return (
    <div className={ className }>
      <button
        className={ classnames('name', isOpen ? 'open' : 'closed') }
        onClick={ () => setState(oldState => ({ ...oldState, isOpen: !oldState.isOpen })) }
      >
        <b>{ name }</b> | <a href={ url } target="_blank">{ url }</a>
      </button>

      { isOpen && (
        <button
          className="edit"
          onClick={ () => setState(oldState => ({ ...oldState, isFormVisibleOpen: !oldState.isFormVisibleOpen })) }
        >
          <Icon type="edit" size={ 24 } />
        </button>
      ) }

      { isOpen && (
        <div className="container">
          <div className="inner" ref={ containerRef }>
            { isFormVisibleOpen && (
              <LinkForm
                className="edit-form"
                onSubmit={ updateLink }
                initialData={ initialData }
              />
            ) }

            <iframe src={ url } title={ name } { ...size } />
          </div>
        </div>
      ) }
    </div>
  );
};

interface LinkItemProps {
  _id: string;
  url: string;
  name: string;
  width: number;
  height: number;
  loadData: () => any;
}

interface LinkItemState {
  isOpen: boolean;
  isFormVisibleOpen: boolean;
}
