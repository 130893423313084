import React, { FC } from 'react';
import { Form } from 'react-final-form';

import { Modal } from 'components/common';
import { Input, Button } from 'components/forms';
import { useRegister } from 'store/user';

import './Register.scss';

const formSubscription = {
  invalid: true,
  submitting: true,
  submitFailed: true,
  dirtySinceLastSubmit: true,
  submitError: true,
};

export const RegisterPage: FC = () => (
  <Form onSubmit={ useRegister() } subscription={ formSubscription }>
    { ({ handleSubmit, invalid, submitting, dirtySinceLastSubmit, submitError, submitFailed }) => (
      <Modal
        isOpen
        isClosable={ false }
        isLoading={ submitting }
        error={ !dirtySinceLastSubmit && submitError }
        className="login-page"
        heading="Please enter your login details below:"
      >
        <form onSubmit={ handleSubmit } noValidate>
          <Input
            name="name"
            type="text"
            label="Name"
            className="input-wrapper"
            required
          />

          <Input
            name="email"
            type="email"
            label="Email"
            className="input-wrapper"
            required
          />

          <Input
            name="password"
            type="password"
            label="Password"
            className="input-wrapper"
            required
          />

          <Button
            className="login-button"
            disabled={ invalid && (!submitFailed || !dirtySinceLastSubmit) }
          >
            Register
          </Button>
        </form>
      </Modal>
    ) }
  </Form>
);
