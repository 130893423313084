import React, { FC, useCallback } from 'react';
import FacebookLogin, { ReactFacebookLoginInfo } from 'react-facebook-login';
import GoogleLogin, { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';

import { Section } from 'components/common';
import { fbAppId, googleClientId, publisherNeededTitle } from 'environment';
import { notifications } from 'utils/notifications';
import { accessTokenAPI } from 'api/access-token';
import { useIsPublisher } from 'store/user';
import { resolveError } from 'api/utils';
import { useApi } from 'hooks/api';

import { AccountSection } from './AccountSection';

import './index.scss';

export const SocialAccounts: FC<SocialAccountsProps> = ({ orgId }) => {
  const [accessTokenData, isAccessTokensLoading, { setData }] = useApi(accessTokenAPI.getAll, [], { fetchData: orgId });
  const isPublisher = useIsPublisher();

  const handleFacebookLogin = useCallback(async (userData: ReactFacebookLoginInfo) => {
    if (userData.accessToken) {
      try {
        const newTokens = await accessTokenAPI.addNewFacebookToken(orgId, userData);
        setData(newTokens);
      } catch (error) {
        notifications.error(resolveError(error));
      }
    }
  }, [orgId, setData]);

  const handleInstagramLogin = useCallback(async (userData: ReactFacebookLoginInfo) => {
    if (userData.accessToken) {
      try {
        const newTokens = await accessTokenAPI.addNewInstagramToken(orgId, userData);
        setData(newTokens);
      } catch (error) {
        notifications.error(resolveError(error));
      }
    }
  }, [orgId, setData]);

  const handleGoogleLogin = useCallback((userData: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    // eslint-disable-next-line no-console
    console.log({ userData });
  }, []);

  return (
    <Section
      className="wnf-social-accounts"
      heading="Social accounts"
      contentClassName="grid"
    >
      <AccountSection
        heading="Facebook"
        tokens={ accessTokenData }
        provider="facebook"
        orgId={ orgId }
        setData={ setData }
        loading={ isAccessTokensLoading }
        buttonTitle={ !isPublisher ? publisherNeededTitle : undefined }
      >
        <FacebookLogin
          appId={ fbAppId }
          scope="pages_read_engagement,business_management"
          callback={ handleFacebookLogin }
          isDisabled={ !isPublisher }
          cssClass="facebook-login"
          version="15.0"
        />
      </AccountSection>

      <AccountSection
        heading="Instagram"
        tokens={ accessTokenData }
        provider="instagram"
        orgId={ orgId }
        setData={ setData }
        loading={ isAccessTokensLoading }
        buttonTitle={ !isPublisher ? publisherNeededTitle : undefined }
      >
        <FacebookLogin
          appId={ fbAppId }
          scope="pages_read_engagement,business_management,instagram_basic,instagram_manage_insights"
          callback={ handleInstagramLogin }
          isDisabled={ !isPublisher }
          cssClass="instagram-login"
          version="15.0"
        />
      </AccountSection>

      <AccountSection
        heading="Google"
        tokens={ accessTokenData }
        provider="google"
        orgId={ orgId }
        setData={ setData }
        loading={ isAccessTokensLoading }
        disabled
      >
        <GoogleLogin
          clientId={ googleClientId }
          onSuccess={ handleGoogleLogin }
          cookiePolicy="single_host_origin"
          className="google-login"
          disabled
        />
      </AccountSection>
    </Section>
  );
};

interface SocialAccountsProps {
  orgId: string;
}
