import React, { FC, useMemo, lazy, Suspense } from 'react';
import { MonacoEditorProps } from 'react-monaco-editor';
import classnames from 'classnames';

import { Spinner } from 'components/common';

import './Editor.scss';

const MonacoEditor = lazy(() => import('react-monaco-editor'));

export const Editor: FC<EditorProps> = ({
  height = 100,
  language,
  code,
  readOnly,
  noMinimap,
  noLineHighlight,
  ...rest
}) => {
  // TODO: Fixe resize issue
  const options = useMemo<MonacoEditorProps['options']>(() => ({
    readOnly,
    minimap: { enabled: noMinimap !== true },
    renderLineHighlight: noLineHighlight ? 'none' : 'line',
    scrollbar: {
      verticalScrollbarSize: 0,
    },
    lineNumbersMinChars: 3,
    lineDecorationsWidth: 0,
  }), [noLineHighlight, noMinimap, readOnly]);

  return (
    <div className={ classnames('sec-editor', language) }>
      <Suspense fallback={ <div className="loading"><Spinner /></div> }>
        <MonacoEditor
          height={ height }
          width="100%"
          language={ language }
          theme="vs-dark"
          value={ code }
          options={ options }
          { ...rest }
        />
      </Suspense>
    </div>
  );
};

interface EditorProps extends MonacoEditorProps {
  code: string;
  language: string;
  height?: number;
  readOnly?: boolean;
  noMinimap?: boolean;
  noLineHighlight?: boolean;
}
