import React, { FC, useCallback, useRef } from 'react';

import { useApi, useApiCallback } from 'hooks/api';
import { organisationAPI } from 'api/organisation';
import { UserRegisterData } from 'api/user';
import { Modal, Section } from 'components/common';
import { Button, Form, Input, SelectField } from 'components/forms';
import { UserRole } from 'store/user/types';
import { useIsAdmin } from 'store/user';
import { adminNeededTitle } from 'environment';

import { UserCard, roleOptions } from './UserCard';

import './index.scss';

export const Users: FC<UsersProps> = ({ orgId }) => {
  const [users, isUsersLoading, { reFetch }] = useApi(organisationAPI.getUsers, [], { fetchData: orgId });
  const { callback: addUser } = useApiCallback(organisationAPI.addUser);
  const modalRef = useRef<Modal>(null);
  const isAdmin = useIsAdmin();
  const handleAddUser = useCallback(async (data: UserRegisterData) => {
    try {
      await addUser(orgId, data);
      reFetch(orgId);

      if (modalRef.current) {
        modalRef.current.close();
      }
    } catch {}
  }, [addUser, reFetch, orgId]);

  return (
    <Section
      className="wnf-users"
      heading="Users"
      loading={ isUsersLoading }
    >
      <table>
        <thead>
          <tr>
            <th className="avatar">Name</th>
            <th> </th>
            <th>Email</th>
            <th>Role</th>
            <th className="password-col"> </th>
          </tr>
        </thead>

        <tbody>
          { users.map(({ _id, ...rest }) => (
            <UserCard
              key={ _id }
              _id={ _id }
              orgId={ orgId }
              reloadData={ reFetch }
              { ...rest }
            />
          )) }
        </tbody>
      </table>
      <Modal
        ref={ modalRef }
        heading="Enter user details"
        renderActions={ modal => (
          <Button
            icon="plus"
            size="large"
            type="primary"
            onClick={ modal.open }
            htmlDisabled={ !isAdmin }
            title={ !isAdmin ? adminNeededTitle : undefined }
          >
            New user
          </Button>
        ) }
      >
        <Form
          onSubmit={ handleAddUser }
          contentClassName="wnf-add-user"
          buttonLabel="Add user"
          buttonWidth={ 180 }
        >
          <Input name="name" label="Name" required />
          <Input name="email" type="email" label="Email" required />

          <div className="row">
            <Input name="password" type="password" label="Password" required />
            <SelectField
              name="role"
              label="User role"
              options={ roleOptions }
              defaultValue={ UserRole.admin }
            />
          </div>
        </Form>
      </Modal>
    </Section>
  );
};

interface UsersProps {
  orgId: string;
}
