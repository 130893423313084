import React, { FC } from 'react';

import { Spinner } from 'components/common';
import { useSelectedOrganisation } from 'store/organisation';

import { SocialAccounts } from './social-accounts';
import { Users } from './users';

import './SettingsPage.scss';

export const SettingsPage: FC = () => {
  const organisation = useSelectedOrganisation();

  if (!organisation) {
    return <Spinner />;
  }

  return (
    <div className="settings-page">
      <h1>{organisation.name} Settings</h1>

      <SocialAccounts orgId={ organisation._id } />
      <Users orgId={ organisation._id } />
    </div>
  );
};
