import axios from 'axios';

import { apiBase } from 'environment';

import { getHeaders } from './utils';

export const API = {
  get<T>(url: string) {
    return axios.get<T>(`${apiBase}/${url}`, getHeaders());
  },
  post<T>(url: string, data: { [key: string]: any }) {
    return axios.post<T>(`${apiBase}/${url}`, data, getHeaders());
  },
  delete<T>(url: string, data?: { [key: string]: any }) {
    return axios.delete<T>(`${apiBase}/${url}`, {
      ...getHeaders(),
      data,
    });
  },
};
