import React, { useMemo } from 'react';
import { Select as AntSelect } from 'antd';
import { SelectProps as AntSelectProps } from 'antd/lib/select';
import classnames from 'classnames';

export const Select = <ValueType extends string | any>({
  className: passedClassName,
  options,
  children,
  label,
  required,
  name,
  ...props
}: SelectProps<ValueType>) => {
  const randomId = useMemo(() => (
    `input-${name}-${Math.floor(Math.random() * 10000)}`
  ), [name]);

  return (
    <React.Fragment>
      { label && (
        <label className="label" htmlFor={ randomId }>
          { `${label}${required ? '*' : ''}` }
        </label>
      ) }

      <AntSelect
        className={ classnames('pro-select-field', passedClassName) }
        { ...props as any }
      >
        {
          children || options?.map(({ value, ...rest }) => (
            <AntSelect.Option key={ String(value) } value={ value as any } { ...rest }>
              { rest.label || String(value) }
            </AntSelect.Option>
          ))
        }
      </AntSelect>
    </React.Fragment>
  );
};

export type SelectFieldValue = string | string[] | number | number[];

// eslint-disable-next-line max-len
export interface SelectProps<ValueType = SelectFieldValue> extends Omit<AntSelectProps<ValueType>, 'options' | 'onChange'> {
  label?: string;
  options: SelectOption<ValueType>[];
  onChange?: (value: ValueType, option: any) => any;
  required?: boolean;
  name?: string;
}

export interface SelectOption<ValueType = string> {
  value: ValueType;
  label?: string;
  title?: string;
  disabled?: boolean;
}
