import React, { FC, useCallback, useState, cloneElement, ReactElement } from 'react';
import ReactJson from 'react-json-view';
import classnames from 'classnames';

import { Icon, Modal, Section } from 'components/common';
import { AccessToken } from 'store/organisation/types';
import { useApiCallback } from 'hooks/api';
import { accessTokenAPI } from 'api/access-token';
import { Button } from 'components/forms';

export const AccountSection: FC<AccountSectionProps> = ({
  className: providedClassName,
  tokens: providedTokens,
  orgId,
  setData,
  children,
  provider,
  heading,
  loading,
  disabled,
  buttonTitle,
}) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const {
    data: updatedAccessTokens,
    callback: updateAccessTokens,
    isLoading: isAccessTokensUpdating,
  } = useApiCallback(accessTokenAPI.updateInfo);
  const {
    callback: revokeAccessTokens,
    isLoading: isRevoking,
  } = useApiCallback(accessTokenAPI.revokeTokens);
  const reloadTokenInfo = useCallback(async () => {
    const newTokens = await updateAccessTokens(orgId);
    setData(newTokens);
  }, [updateAccessTokens, orgId, setData]);

  const allTokens = updatedAccessTokens || providedTokens;
  const tokens = allTokens.filter(token => token.provider === provider);
  const authenticated = !!tokens?.filter(token => !token.error && token.type === 'PAGE').length;
  const className = classnames(
    'wnf-social-account-section',
    disabled && 'disabled',
    providedClassName,
  );
  const childProps = {
    ...((provider === 'facebook' || provider === 'instagram') && {
      onClick: () => setIsUpdating(true),
      callback: async (...props: any[]) => {
        await children.props.callback(...props);
        setIsUpdating(false);
      },
    }),
    ...(provider === 'facebook' && {
      textButton: `${authenticated ? 'Reauthorize' : 'Authorize'} Facebook`,
      icon: <Icon size={ 24 } type="facebook" className="icon" />,
    }),
    ...(provider === 'instagram' && {
      textButton: `${authenticated ? 'Reauthorize' : 'Authorize'} Instagram`,
      icon: <Icon size={ 24 } type="instagram" className="icon" />,
    }),
    ...(provider === 'google' && {
      onRequest: () => setIsUpdating(true),
      buttonText: `${authenticated ? 'Reauthorize' : 'Authorize'} Google`,
      icon: <Icon size={ 24 } type="instagram" className="icon" />,
      onSuccess: async (...props: any[]) => {
        await children.props.onSuccess(...props);
        setIsUpdating(false);
      },
    }),
  };

  return (
    <Section
      className={ className }
      contentClassName="content"
      headingClassName="heading"
      title={ disabled ? 'Unavailable' : undefined }
      loading={ loading || isUpdating }
      heading={ heading }
    >
      <div className="line">
        <Icon
          type={ authenticated ? 'check' : 'warning' }
          className={ classnames('icon', authenticated ? 'ok' : 'warning') }
          size={ 20 }
        />
        Status:&nbsp;<b>{ authenticated ? 'Authorized' : 'Unauthorized' }</b>

        { !!tokens?.length && (
          <Modal
            className="wnf-token-info-modal"
            isLoading={ isAccessTokensUpdating || isRevoking }
            renderActions={ modal => (
              <Icon
                className="token-info"
                onClick={ modal.open }
                title="More info"
                type="info"
              />
            ) }
            renderHeading={ modal => (
              <div className="heading-actions">
                Token info

                <Icon
                  type="reload"
                  title="Reload token info"
                  onClick={ reloadTokenInfo }
                  className={ classnames('reload', isAccessTokensUpdating && 'loading') }
                />
                <Button
                  htmlType="button"
                  onClick={
                    async () => {
                      await revokeAccessTokens(orgId);
                      modal.close();
                      setData([]);
                    }
                  }
                  type="primary"
                  icon="delete"
                  danger
                >
                  Revoke tokens
                </Button>
              </div>
            ) }
          >
            { tokens.map(tokenInfo => (
              <div className="token-json" key={ tokenInfo.id }>
                <ReactJson src={ tokenInfo } collapsed={ 1 } displayDataTypes={ false } />
              </div>
            )) }
          </Modal>
        ) }
      </div>

      <div className="line cta" title={ buttonTitle }>
        { cloneElement(children, childProps) }
      </div>
    </Section>
  );
};

interface AccountSectionProps {
  tokens: AccessToken[];
  orgId: string;
  setData: (newTokens: AccessToken[]) => any;
  provider: AccessToken['provider'];
  heading: string;
  loading: boolean;
  children: ReactElement;
  className?: string;
  disabled?: boolean;
  buttonTitle?: string;
}
