import React, { FC, Fragment } from 'react';
import AntSwitch, { SwitchProps as AntSwitchProps } from 'antd/lib/switch';
import classnames from 'classnames';
import 'antd/lib/switch/style';

import './Switch.scss';

export const Switch: FC<SwitchProps> = ({
  className: passedClassName,
  label,
  ...props
}) => (
  <Fragment>
    { label && <div className="label">{ label }</div> }

    <AntSwitch
      className={ classnames('switch', passedClassName) }
      { ...props }
    />
  </Fragment>
);

export interface SwitchProps extends AntSwitchProps {
  label?: string;
}
