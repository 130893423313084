import { API } from 'api';
import { Organisation } from 'store/organisation/types';
import { User, UserRole } from 'store/user/types';

import { UserRegisterData } from './user';

const ORGANISATION_API_PREFIX = 'organisations';

export const organisationAPI = {
  addNew: async (data: { name: string }) => {
    const response = await API.post<Organisation>(ORGANISATION_API_PREFIX, data);
    return response.data;
  },
  getUsers: async (orgId: string) => {
    const response = await API.get<GetUsersRespose[]>(`${ORGANISATION_API_PREFIX}/${orgId}/users`);
    return response.data;
  },
  addUser: async (orgId: string, data: UserRegisterData) => {
    const response = await API.post<User[]>(`${ORGANISATION_API_PREFIX}/${orgId}/users`, data);
    return response.data;
  },
  updateUserRole: async (orgId: string, userId: string, role: UserRole) => {
    const response = await API.post<void>(`${ORGANISATION_API_PREFIX}/${orgId}/users/${userId}/role`, { role });
    return response.data;
  },
};

export interface GetUsersRespose extends User {
  role: UserRole;
}
