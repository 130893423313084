import React, { FC } from 'react';
import AntTable, { TableProps as AntTableProps, ColumnProps } from 'antd/lib/table';
import classnames from 'classnames';
import 'antd/lib/table/style';

import './Table.scss';

export const Table: FC<TableProps> = ({
  className: passedClassName,
  size = 'middle',
  ...props
}) => (
  <AntTable
    className={ classnames('table', passedClassName) }
    size={ size }
    bordered
    { ...props }
  />
);

export type TableProps = AntTableProps<any>;

export type { ColumnProps };
